import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Article/articleLayout.js";
import Carousel from "../../../../components/Carousel";
import { graphql } from "gatsby";
import ArticleCarouselItem from "../../../../components/Article/articleCarouselItem";
export const pageQuery = graphql`
 {
  carousel: allFile(filter: {relativeDirectory: {eq:"digital/tinkerman/carousel"}}, sort: {fields: name, order: ASC}){
      nodes{
        name
        childImageSharp{
          fluid(quality: 100){
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
 }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Tinkerman Expressions is a creative director. He takes ideas and brings them to life using beautiful storytelling techniques.`}</h2>
    <p>{`The objective was to create a logo that aptly represents his brand.`}</p>
    <h4><span parentName="h4" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "710px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.70422535211267%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA1ElEQVQoz41S2w6DIAz1/79tWbKHvW97MFuCOgVEpHAG3uPwQlJaKD09LU2ws5xzk17aeyvZCp5AFgBnQJMYo9n2oGEnA2ept9cJVxX8MWzbFsaY/s7aXn9TuPK92ZYlkSggrzg459BaQykFVXxgRAEpBBp/DiKl7PyHDIkIRV4gz3MwxpCxbACquyQVF8i8L/hDsnVfo59ifalBB7Y0lG/rEvS4gNI7mtcN5nmF9W1wWwwPRyIEtTVgdC+kfY/NHLfHMCrju7WMcbGxOTPcW2MzMvwBK7AW6u4XtA8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tinkerman",
          "title": "Tinkerman",
          "src": "/static/61477fd1dd6a7791e3389411229a587c/911ab/tinkerman.png",
          "srcSet": ["/static/61477fd1dd6a7791e3389411229a587c/911ab/tinkerman.png 710w"],
          "sizes": "(max-width: 710px) 100vw, 710px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></h4>
    <p>{`The simple design cleverly mimics a body and the orange colour was selected because it represents fun and creativity.`}</p>
    <Carousel marginBottom={50} mdxType="Carousel">
  {props.data.carousel.nodes.map(image => <ArticleCarouselItem fluid={image.childImageSharp.fluid} mdxType="ArticleCarouselItem" />)}
    </Carousel>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      